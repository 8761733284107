<template>
  <div>
    <loader v-if="isLoading"></loader>
    <div id="gridLayout" v-else>
      <div id="gridHead">
        <p class="ht1">Кому:</p>
        <p class="hv1">{{dg.CLFullName}}</p>

        <p class="ht2">Aдрес:</p>
        <p class="hv2">{{dg.ClRegAddress}}</p>

        <p class="ht3">От:</p>
        <p class="hv3">{{dg.OrgNameFrom}}</p>

        <p class="ht41">ОГРН:</p>
        <p class="hv42">{{dg.OrgOgrnFrom}}</p>
        <p class="ht43">ИНН:</p>
        <p class="hv44">{{dg.OrgInnFrom}}</p>

        <p class="ht5">Aдрес:</p>
        <p class="hv5">{{dg.OrgAdrFrom}}</p>

        <p class="ht61">Телефон:</p>
        <p class="hv62">{{dg.OrgTelFrom}}</p>
        <!--        <p class="ht63">Факс:</p>-->
        <!--        <p class="hv64">{+7 (123) 123-32-32}</p>-->

        <p class="ht7">Адрес электронной почты:</p>
        <p class="hv7">{{dg.OrgEmailFrom}}</p>

      </div>
      <div id="gridTitle">
        <p>Уведомление<br>
          о состоявшейся уступке прав требования
        </p>
      </div>
      <div id="gridContent">
        <p>
          {{dg.OrgNameFrom}}, в соответствии со ст. 9 Федерального закона от 03.07.2016 N 230-ФЗ "О защите прав и
          законных интересов физических лиц при осуществлении деятельности по возврату просроченной задолженности и о
          внесении изменений в Федеральный закон "О микрофинансовой деятельности и микрофинансовых организациях",
          уведомляет  {{dg.CLFullName}} о том, что с {{dg.DateIn | timeFromXtoDateView}}, новым кредитором по Договору займа от
          {{dg.DlDate | timeFromXtoDateView}} № {{dg.DlCode}} является {{dg.OrgNameTo}} (ОГРН {{dg.OrgOgrnTo}} ИНН {{dg.OrgInnTo}} Адрес
          {{dg.OrgAdrTo}}, Тел
          {{dg.OrgTelTo}}, e-mail {{dg.OrgEmailTo}}), на основании ДОГОВОРА УСТУПКИ ПРАВ ТРЕБОВАНИЯ ОТ {{dg.OrgDateDocTO | timeFromXtoDateView}} №
          {{dg.OrgNumDocTo}}.
        </p>

        <p> По состоянию на {{dg.DateIn | timeFromXtoDateView}} задолженность по Договору займа от {{dg.DlDate |
          timeFromXtoDateView}} №{{dg.DlCode}}
          составляет {{dg.totSum | moneyIntlFormatNumber({minimumFractionDigits:2})}} рублей ({{dg.totSum | intToWords}}), в том
          числе: основной долг - {{dg.sumOd | moneyIntlFormatNumber({minimumFractionDigits:2})}} рублей ({{dg.sumOd | intToWords}}), начисленные
          проценты - {{dg.sumPerc | moneyIntlFormatNumber({minimumFractionDigits:2})}} рублей ({{dg.sumPerc |
          intToWords}}).</p>
        <p>
          Погашение задолженности необходимо осуществлять по следующим реквизитам:
        </p>
        <div id="bankInfo">
          <p>Банк получателя</p>
          <p>{{dg.BankName}}</p>

          <p>Номер счета</p>
          <p>{{dg.BankNum}}</p>

          <p>БИК</p>
          <p>{{dg.BankBik}}</p>

          <p>Кор. счет</p>
          <p>{{dg.BankCorr}}</p>

          <p>КПП Банка</p>
          <p>{{dg.BankKpp}}</p>

          <p>ИНН Банка</p>
          <p>{{dg.BankINN}}</p>
        </div>
      </div>
      <div id="gridFooter">
        <p>"___"__________ ____ г.</p>
        <p>Кредитор (представитель):</p>
        <p>___________________/_____________________ (подпись, Ф.И.О.)</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "cession",
    data(){
      return {
        isLoading:true,
        dg: {
          BankBik: "",
          BankCorr: "",
          BankKpp: "",
          BankName: "",
          BankNum: "",
          CLFullName: "",
          ClFactAddress: "",
          ClRegAddress: "",
          DateIn: "",
          DlCode: "",
          DlDate: "",
          DlPhone: "",
          OrgAdrFrom: "",
          OrgEmailFrom: "",
          OrgInnFrom: "",
          OrgNameFrom: "",
          OrgOgrnFrom: "",
          OrgTelFrom: "",
          OrgAdrTo:"",
          OrgDateDocTO:"",
          OrgEmailTo:"",
          OrgInnTo:"",
          OrgNameTo:"",
          OrgNumDocTo:"",
          OrgOgrnTo: "",
          OrgTelTo:"",
          sumOd: 0,
          sumPerc: 0,
          totSum: 0
        }
      }
    }
  ,
  components: {
    loader: () => import('@/components/other/loader'),
  },
    mounted(){
      if (this.$route.params.uid !== undefined) {
        this.$external.universalRequest({
          action:'getCession',
          externalType: 'zita',
          DemandGuid:this.$route.params.uid
        }).then(res=>{
          this.dg = res;
          this.isLoading = false;
        }).catch(res=>{
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        })
      } else {
        alert('Не корректная ссылка')
      }
    }
  }
</script>

<style scoped>
  p {margin: 0 0 2px;}
  #gridLayout { display: grid;width: 620px;margin: 0 auto;background: white;padding: 10px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1.7fr;
    grid-template-rows: 0.5fr 0.5fr 2fr 0.5fr;
    grid-template-areas: ". . a" "b b b" "c c c" ". . d";line-height: 1.5 ;min-height: 100vh;font-size: 9pt}
  #gridHead {grid-area: a;display: grid;grid-auto-columns: 1fr;grid-auto-rows: min-content;grid-template-columns: 0.3fr 1fr 0.3fr 1fr;
    grid-template-areas:
      "ht1 hv1 hv1 hv1"
      "ht2 hv2 hv2 hv2"
      "ht3 hv3 hv3 hv3"
      "ht41 hv42 ht43 hv44"
      "ht5 hv5 hv5 hv5"
      "ht61 hv62 hv62 hv62"
      "ht7 hv7 hv7 hv7"
  ;
    gap: 2px 5px;font-size: 8pt;
  }
  #bankInfo{
    display: grid;
    width:50%;
    grid-template-columns: 1fr 2fr;
  }
  .ht1{grid-area: ht1}
  .hv1{grid-area: hv1}
  .ht2{grid-area: ht2}
  .hv2{grid-area: hv2}
  .ht3{grid-area: ht3}
  .hv3{grid-area: hv3}
  .ht41{grid-area: ht41}
  .hv42{grid-area: hv42}
  .ht43{grid-area: ht43}
  .hv44{grid-area: hv44}
  .ht5{grid-area: ht5}
  .hv5{grid-area: hv5}
  .ht61{grid-area: ht61}
  .hv62{grid-area: hv62}
  .ht63{grid-area: ht63}
  .hv64{grid-area: hv64}
  .ht7{grid-area: ht7}
  .hv7{grid-area: hv7}
  #gridTitle {grid-area: b;display: flex;align-items: center;justify-content: center;text-align: center;font-weight: bold}
  #gridContent {grid-area: c;}
  #gridFooter {grid-area: d;}

  @media print {
    #gridLayout{width: 100%;background: transparent;margin: 0;padding: 0;box-shadow:none;}
  }

</style>
